// @doc off
// Extends the bottom of the element to enclose any floats it contains.
// @doc on

@import "hacks";

// This basic method is preferred for the usual case, when positioned
// content will not show outside the bounds of the container.
//
// Recommendations include using this in conjunction with a width.
// Credit: [quirksmode.org](http://www.quirksmode.org/blog/archives/2005/03/clearing_floats.html)
@mixin clearfix {
  overflow: hidden;
  @include has-layout; }

// This older method from Position Is Everything called
// [Easy Clearing](http://www.positioniseverything.net/easyclearing.html)
// has the advantage of allowing positioned elements to hang
// outside the bounds of the container at the expense of more tricky CSS.
@mixin legacy-pie-clearfix {
  &:after {
    content: "\0020";
    display: block;
    height: 0;
    clear: both;
    overflow: hidden;
    visibility: hidden; }
  @include has-layout; }

// This is an updated version of the PIE clearfix method that reduces the amount of CSS output.
// If you need to support Firefox before 3.5 you need to use `legacy-pie-clearfix` instead.
//
// Adapted from: [A new micro clearfix hack](http://nicolasgallagher.com/micro-clearfix-hack/)
@mixin pie-clearfix {
  &:after {
    content: "";
    display: table;
    clear: both; }
  @include has-layout; }
