
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@include keyframes(no-anim) {

}


@mixin animation ($stuff ) {
  -webkit-animation: $stuff;
  -moz-animation: $stuff;
  -ms-animation: $stuff;
  animation: $stuff;
}


@include keyframes(floating) {
  0% {
    @include transform( translateY(0) );
  }
  50% {
    @include transform( translateY(20%) );
  }
  100% {
    @include transform( translateY(0));
  }
}
