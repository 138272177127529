hr.hr-skills{
  @include hr-custom($main-hr-skills-color);
}


.section-model-2 {

  background-color: $main-secondary-color;
  width: 100%;
  height: auto;

  header {
    text-align: center;
    font-family: $ubuntu-font;
    padding-bottom: 20px;
    width: 80%;
    position: relative;
    left: 50%;
    margin-left: -40%;
    font-size: 13px;
    margin-bottom: 22px;

    @include selection(black, white);

    @include main-section-h2;

    h2{
      color: $main-primary-color;
      display: inline-block;

    }

  }

  .container {

    @include container;
    padding-bottom: 20px;
    font-family: $roboto-font;

    @include selection(black, white);

    ul{

      list-style: none;
      width: 100%;
      padding: 0;
      margin: 0;

      li{
        width: 100%;
        height: 220px;
        margin-bottom: 30px;

        @include more-mobile-landscape{
          @include span(6 of 12);
        }

        @include breakpoint($desktop){
          @include span(4 of 12);
        }


        .content{

          width: 95%;

          .img-group{
            height: 100px;
            text-align: center;
            line-height: 130px;
            vertical-align: middle;
            padding-top: 20px;

            i{
              line-height: 120px;
              color: $main-primary-color;
              font-size: 15vw;

              @include more-mobile-landscape{
                font-size: 7vw;
              }

              @include breakpoint($desktop){
                font-size: 5vw;
              }
            }

            img{
              max-width: 15vw;
              margin-left: 5px;

              @include more-mobile-landscape{
                max-width: 7vw;
              }

              @include breakpoint($desktop){
                max-width: 5vw;
              }

              &:first-child{
                margin-left: 0;
              }
            }
          }

          .text{
            height: 100px;
            text-align: center;
            color: #fff;
            font-size: 15px;
            font-weight: 300;
            padding: 0 7px 7px 7px;
          }


        }


      }

      li:nth-child(even){

        .content{
          background-color: rgba(#000,0.04);
          margin: 0 auto;
        }
        @include mobile-landscape{
          .content{
            margin: 0 0 auto auto;
          }
        }
      }

      li:nth-child(odd){
        .content{
          background-color: darken($main-secondary-color, 5%);
          margin: 0 auto;
        }

        @include mobile-landscape{
          .content{
            margin: auto auto 0 0;
          }
        }
      }

      li:nth-of-type(4n){
        @include breakpoint($desktop){
          .content{
            margin-left: 0;
          }
        }
      }

      li:nth-of-type(3n){
        @include breakpoint($desktop){
          .content{
            margin: auto 0 auto auto;
          }
        }
      }

      li:nth-of-type(3n + 2){
        @include breakpoint($desktop){
          .content{
            margin: auto;
          }
        }
      }

    }
  }
}